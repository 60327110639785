import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
// import swal from "sweetalert";
// import axios from "axios";
// import { isAutheticated } from "src/auth";
import MessageList from "./MessageList";
import { Button } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import Axios from "../../axios";

const CloseRequestView = () => {
  const { ticketID } = useParams();
  // const token = isAutheticated();
  const history = useHistory();
  const jwt = localStorage.getItem("jwt");
  console.log(jwt);

  const [loading, setLoading] = useState(false);
  const [ticketDetails, setticketDetails] = useState(null);

  const getSupportTicketDetails = async () => {
    Axios.get(`/api/support/getOne/${ticketID}`, {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        jwt: `${jwt}`,
      },
    })
      .then((res) => {
        console.log(res);
        setticketDetails(res.data?.support);
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "Please login to access the resource or refresh the page",
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };

  // const getuserName = async (ticket) => {
  //   try {
  //     const userId = ticket.addedBy;
  //     let resp = await Axios.get(`/api/v1/admin/user/${userId}`, {
  //       headers: {

  //       },
  //     });
  //     const userName = resp?.data?.user?.name;
  //     const userMailId = resp?.data?.user?.email;

  //     const ticketWithUserName = {
  //       ...ticket,
  //       userName: userName,
  //       userMailId: userMailId,
  //     };
  //     setticketDetails(ticketWithUserName);
  //   } catch (error) {
  //     // Handle errors
  //   }
  // };

  useEffect(() => {
    getSupportTicketDetails();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div style={{ fontSize: "22px" }} className="fw-bold">
              View Request
            </div>
            <div className="page-title-right">
              <Button
                variant="outline-danger"
                style={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  history.push("/customer/support/close");
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 my-1">
          <div className="card h-100">
            <div className="card-body px-5">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Ticket ID: {ticketDetails?.ticketId}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  User ID: {ticketDetails?.addedBy._id}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  User Name: {ticketDetails?.addedBy?.name}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  User Number: {ticketDetails?.addedBy?.number}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  Date and Time: {ticketDetails?.createdOn}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  Subject: {ticketDetails?.subject}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  Description: {ticketDetails?.description || "No Description"}
                </label>
              </div>
              <div className="mb-3">
                {ticketDetails?.image?.length > 0 && (
                  <div>
                    <label htmlFor="name" className="form-label">
                      Image:
                    </label>
                    <div className="image-list d-flex flex-wrap">
                      {ticketDetails?.image?.map((item) => (
                        <div key={item.public_id} className="image-list-item">
                          <img
                            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.title || "No image"}
                            loading="lazy"
                            className="img-thumbnail"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-3">
                {ticketDetails && (
                  <MessageList messages={ticketDetails?.message} />
                )}
              </div>

              {/* *************** */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseRequestView;
