import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Row,
  Table,
  ListGroup,
  Badge,
  Button,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
//import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { pageVisits } from "../../data/tables";

import { useParams } from "react-router-dom";
import Axios from "./../../axios";
const ViewUser = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  const referralData = [
    { name: "Alice Brown", date: "2024-02-01" },
    { name: "Bob Wilson", date: "2024-02-15" },
    { name: "Carol Davis", date: "2024-03-01" },
    { name: "David Miller", date: "2024-03-10" },
  ];

  const userData = {
    userName: "John Smith",
    referralCode: "JOHN123",
    planName: "Premium",
    planType: "Annual",
    keywords: [
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
    ],
    mobileNumber: "+1-234-567-8900",
    joiningDate: "2024-01-15",
    // Optional fields
    ipAddress: "192.168.1.1",
    os: "Windows 11",
    deviceName: "Desktop-PC",
    deviceResolution: "1920x1080",
    location: "New York, USA",
    deviceId: "DEV-123456",
  };

  const renderField = (label, value) => (
    <ListGroup.Item>
      <Row className="align-items-start">
        <Col xs={4} className="text-start pe-2">
          <strong className="text-muted">{label}:</strong>
        </Col>
        <Col xs={8} className="ps-3 border-start">
          {value}
        </Col>
      </Row>
    </ListGroup.Item>
  );

  //   const TableRow = (props) => {
  //     const { pageName, views, returnValue, bounceRate } = props;
  //     const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
  //     const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

  //     return (
  //       <tr>
  //         <th scope="row">{pageName}</th>
  //         <td>{views}</td>
  //         <td>${returnValue}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={bounceIcon}
  //             className={`${bounceTxtColor} me-3`}
  //           />
  //           {Math.abs(bounceRate)}%
  //         </td>
  //       </tr>
  //     );
  //   };
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata",
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await Axios.get(`/api/admin/user/${id}`);

        console.log(response);

        setData(response.data.user);
        setLoading(false);
      };

      fetchData();
    } catch (e) {
      console.log(e.response.data);
    }
  }, []);

  if (loading) {
    return <h6> loading </h6>;
  }
  return (
    // <div>
    //   <h1 className="text-center mb-1">Single User profile</h1>
    //   <div className="d-flex w-100">
    //     <Card
    //       border="light"
    //       className=" shadow-sm mb-4"
    //       style={{
    //         background: "#262b40",
    //         color: "white",
    //         width: "50%",
    //         margin: "1rem",
    //         // border: "1px colid white ",
    //         borderRadius: "1rem",
    //       }}
    //     >
    //       <Card.Body style={{ color: "white" }}>
    //         <div className="">
    //           <div className="d-flex justify-content-between">
    //             <Card.Title as="h5" className="mr-2" style={{ color: "white" }}>
    //               {" "}
    //               Name : {data.name}
    //             </Card.Title>
    //             <Card.Title className="mb-2  ml-2" style={{ color: "white" }}>
    //               Unique ID: 155464465
    //             </Card.Title>
    //           </div>
    //           <div>
    //             <Card.Text>{data.subscription.plan.planName}</Card.Text>
    //             <Card.Text>
    //               Referral Code: {data?.referralCode ? data.referralCode : " "}
    //             </Card.Text>
    //             <Card.Text>
    //               Joined On:{" "}
    //               {new Date(data?.createdAt).toLocaleDateString(
    //                 "en-US",
    //                 options
    //               )}
    //             </Card.Text>
    //             {/* <Card.Text>Last logged in: dsd</Card.Text> */}
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //     <Card
    //       border="light"
    //       className="bg-white shadow-sm mb-4"
    //       style={{
    //         // background: "#262b40",
    //         // color: "white",
    //         width: "50%",
    //         margin: "1rem",
    //         // border: "1px colid white ",
    //         borderRadius: "1rem",
    //       }}
    //     >
    //       <Card.Body>
    //         <div className="">
    //           <div className="d-flex">
    //             <Card.Title as="h5" style={{ marginRight: "1rem" }}>
    //               {" "}
    //               Keywords
    //             </Card.Title>
    //           </div>
    //           <div>
    //             <Card.Text>{data.keywords.length} </Card.Text>
    //             <Card.Text>
    //               {data.keywords.length > 0 &&
    //                 data.keywords.map((keyword) => <span>{keyword}</span>)}
    //             </Card.Text>
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //     <Card
    //       border="light"
    //       className=" shadow-sm mb-4"
    //       style={{
    //         background: "#262b40",
    //         color: "white",
    //         width: "50%",
    //         margin: "1rem",
    //         // border: "1px colid white ",
    //         borderRadius: "1rem",
    //       }}
    //     >
    //       <Card.Body>
    //         <div className="">
    //           <div className="d-flex">
    //             <Card.Title
    //               as="h5"
    //               style={{ marginRight: "1rem", color: "white" }}
    //             >
    //               {" "}
    //               Referral
    //             </Card.Title>
    //           </div>
    //           <div>
    //             <Card.Text>Total Number of Referrals signed up: 2 </Card.Text>
    //             <Card.Text> Referral Name: Hi , hello </Card.Text>
    //             <Card.Text> Signed Up Date: date will go here </Card.Text>
    //           </div>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //   </div>
    //   <div>
    //     {/* table of Orders */}
    //     <Card border="light" className="shadow-sm">
    //       <Card.Header>
    //         <h2>Orders</h2>
    //       </Card.Header>
    //       {/* <Card.Header>
    //         <Row className="align-items-center">
    //           <Col>
    //             <h5>Page visits</h5>
    //           </Col>
    //           <Col className="text-end">
    //             <Button variant="secondary" size="sm">
    //               See all
    //             </Button>
    //           </Col>
    //         </Row>
    //       </Card.Header> */}
    //       <Table responsive className="align-items-center table-flush">
    //         <thead className="thead-light">
    //           <tr>
    //             <th scope="col">Date and Time</th>
    //             <th scope="col">Invoice Number</th>
    //             <th scope="col">Razorpay ID</th>
    //             <th scope="col">Package</th>
    //             <th scope="col">Referral Code Used (If yes, mention code)</th>
    //             <th scope="col">Referral Discount (IF Any)</th>
    //             <th scope="col">Amount Paid</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {/* {pageVisits.map((pv) => (
    //             <TableRow key={`page-visit-${pv.id}`} {...pv} />
    //           ))} */}
    //           <tr>
    //             <td>2024-05-16 10:30:00</td>
    //             <td>INV001</td>
    //             <td>RZP001</td>
    //             <td>Basic Package</td>
    //             <td>No</td>
    //             <td>N/A</td>
    //             <td>$50.00</td>
    //           </tr>
    //           <tr>
    //             <td>2024-05-15 15:45:00</td>
    //             <td>INV002</td>
    //             <td>RZP002</td>
    //             <td>Pro Package</td>
    //             <td>Yes</td>
    //             <td>REF123</td>
    //             <td>$75.00</td>
    //           </tr>
    //           <tr>
    //             <td>2024-05-14 09:20:00</td>
    //             <td>INV003</td>
    //             <td>RZP003</td>
    //             <td>Starter Package</td>
    //             <td>No</td>
    //             <td>N/A</td>
    //             <td>$30.00</td>
    //           </tr>
    //         </tbody>
    //       </Table>
    //     </Card>
    //   </div>
    // </div>

    <div className="container mt-4">
      <Row>
        <Row>
          <Col className="d-flex justify-content-start m-2">
            <Link to="/components/user" className="btn btn-primary">
              All Users
            </Link>
          </Col>
          <Col className="d-flex justify-content-end m-2">
            <Button variant="success">User Invoices</Button>
          </Col>
        </Row>
        {/* User Details Section */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-primary text-white py-3">
              <h4 className="mb-0">User Profile</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <ListGroup variant="flush">
                {renderField("User Name", data.name)}
                {renderField("Referral Code", data.referralCode)}
                {renderField("Plan Name", data.subscription.plan.planName)}
                {renderField("Plan Type", data.subscription.planType)}
                {renderField(
                  "Keywords",
                  <div className="d-flex flex-wrap gap-1">
                    {data.keywords.map((keyword, index) => (
                      <span
                        key={index}
                        className="bg-secondary text-white px-2 py-1 rounded-pill small"
                      >
                        {keyword}
                      </span>
                    ))}
                  </div>
                )}
                {renderField("Mobile Number", data.mobileNumber)}
                {renderField("Joining Date", data.createdAt)}
              </ListGroup>

              <>
                {/* <div className="border-top pt-3 pb-2 px-3">
                    <h5 className="text-muted">Additional Details</h5>
                  </div> */}
                <ListGroup variant="flush">
                  {renderField(
                    "IP Address",
                    data.ipAddress ? data.ipAddress : ""
                  )}
                  {renderField("OS", data.os ? data.os : "")}
                  {renderField(
                    "Device Name",
                    data.deviceName ? data.deviceName : ""
                  )}
                  {renderField(
                    "Resolution",
                    data.deviceResolution ? data.deviceResolution : ""
                  )}

                  {renderField("Device ID", data.deviceId ? data.deviceId : "")}
                </ListGroup>
              </>
            </Card.Body>
          </Card>
        </Col>

        {/* Referrals Section */}
        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Header className="bg-success text-white py-3">
              <h4 className="mb-0">Referrals ({referralData.length})</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <ListGroup variant="flush">
                {referralData.map((referral, index) => (
                  <ListGroup.Item key={index}>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <strong>{referral.name}</strong>
                      </Col>
                      <Col xs={6} className="text-end text-muted">
                        {referral.date}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewUser;
