import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "../../axios";
import { Dropdown } from "bootstrap";
import { Button } from "@themesberg/react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

const Plan = () => {
  const [loading, setLoading] = useState(false);
  const [getPlan, setGetPlan] = useState();

  const getPlans = async () => {
    setLoading(true);
    //const jwt = localStorage.getItem("jwt");

    try {
      const respose = await Axios.get("/api/get-plans", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log(respose.data);
      setGetPlan(respose?.data?.plans);
    } catch (err) {
      console.log("error in get user", err);
    }
    setLoading(false);
  };

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  useEffect(() => {
    getPlans();
  }, []);

  const history = useHistory();
  // const rowsPerPage = 8;
  // const [currentPage, setCurrentPage] = useState(1);
  // const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = getPlan?.slice(indexOfFirstRow, indexOfLastRow);

  // const totalPages = Math.ceil(getPlan?.length / rowsPerPage);

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const handleDelete = async (planId) => {
  //   console.log(planId);
  //   const jwt = localStorage.getItem("jwt");

  //   try {
  //       // Sending delete request
  //      const response = await Axios.delete(`/api/delete-plan/${planId}`, {
  //         headers: {
  //           jwt: `${jwt}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       if (response.status === 200) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Plan Deleted  Successfully",
  //           text: "Plan has been deleted successfully!",
  //           button: "OK",
  //         });
  //       }

  //       // Refresh the list of plans after deletion
  //       getPlans();
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "something went wronge",

  //       button: "OK",
  //     });
  //     console.error("Error deleting the plan", error);

  //   }
  // };

  const handleDelete = async (planId) => {
    // console.log(planId);

    // Show confirmation popup before deleting
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Sending delete request
          const response = await Axios.delete(`/api/delete-plan/${planId}`, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              title: "Plan Deleted Successfully",
              text: "Plan has been deleted successfully!",
              button: "OK",
            });
          }

          // Refresh the list of plans after deletion
          getPlans();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "There was an issue deleting the plan.",
            button: "OK",
          });
          console.error("Error deleting the plan", error);
        }
      } else {
        // If the user cancels the delete action, log or do nothing
        console.log("Deletion canceled");
      }
    });
  };

  return (
    <section>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <h1
          style={{ flex: 1, textAlign: "center" }}
          className="text-center mb-1"
        >
          Plans
        </h1>
        <Button
          variant="success"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            textTransform: "capitalize",
            marginRight: "5px",
          }}
          onClick={() => history.push("/add-plan")}
        >
          Add plan
        </Button>
      </div>

      <table className="table table-hover table-responsive-xl table-responsive-md">
        <thead>
          <tr>
            <th scope="col">Plan Name</th>
            <th scope="col">Monthly Price</th>
            <th scope="col">Yearly Price</th>
            <th scope="col">Keyword</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        {loading ? (
          <tbody className="w-100">
            <tr>
              <td colSpan="6" className="text-center">
                <div colSpan="6" className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {getPlan ? (
              getPlan.map((item, index) => (
                <tr key={index}>
                  <th>{item?.planName}</th>
                  <th>{item?.monthlyPrice}</th>
                  <th>{item?.yearlyPrice}</th>

                  <th>{item?.maxkeywords}</th>

                  <th>
                    {" "}
                    {/* <Button>View</Button> */}
                    <button
                      className="btn btn-info"
                      // style={{ background: "black", color: "white" }}
                      onClick={() => history.push(`/update-plan/${item?._id}`)}
                    >
                      Edit
                    </button>
                    {/* <Button>delete</Button> */}
                    <button
                      className="btn ms-3 btn-danger"
                      // style={{ background: "blue", color: "white" }}
                      onClick={() => handleDelete(item?._id)}
                    >
                      Delete
                    </button>
                  </th>
                </tr>
              ))
            ) : (
              <>
                <tr>
                  <td colSpan="6" className="text-center">
                    <p>No Any Plan</p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        )}
      </table>

      {/* <div style={{ cursor: "pointer" }}>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <div
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </div>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}{" "}
                {currentPage === index + 1 && (
                  <span className="sr-only">(current)</span>
                )}
              </div>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <div
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </div>
          </li>
        </ul>
      </div> */}
    </section>
  );
};

export default Plan;
