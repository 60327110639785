import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Button,
} from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Axios from "../../axios";
import Swal from "sweetalert2";

const DownloadUrl = () => {
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchDownloadUrl = async () => {
      try {
        const response = await Axios.get("/api/get-download-url");
        if (response.data) {
          setDownloadUrl(response.data.downloadUrl);
          setIsUpdating(true);
        }
      } catch (error) {
        console.error("Error fetching the download URL:", error);
      }
    };

    fetchDownloadUrl();
  }, []);
  const jwt = localStorage.getItem("jwt");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isUpdating) {
        const response = await Axios.put(
          "/api/update-download-url",
          {
            downloadUrl,
          },
          {
            headers: {
              jwt: `${jwt}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "URL Update successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } else {
        const response = await Axios.post(
          "/api/create-download-url",
          {
            downloadUrl,
          },
          {
            headers: {
              jwt: `${jwt}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 201) {
          Swal.fire({
            title: "Success!",
            text: "URL Update successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        console.log("Create API response:", response.data);
        setIsUpdating(true);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <div>
      <Container>
        <Card style={{ padding: "1rem", margin: "1rem" }}>
          <Row className="justify-content-md-center">
            <Col md="6">
              <h1 className="text-center mb-4">Download URL</h1>
            </Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formDownloadUrl">
                <Form.Label>Download URL</Form.Label>
                <Form.Control
                  type="text"
                  name="downloadUrl"
                  value={downloadUrl}
                  onChange={(e) => setDownloadUrl(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                {isUpdating ? "Update" : "Save"}
              </Button>
            </Form>
          </Row>
        </Card>
      </Container>
    </div>
  );
};

export default DownloadUrl;
