import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Axios from "../axios";

const ViewTransaction = () => {
  const [transaction, setTransaction] = useState(null);
  const [loader,setLoader] =useState(false)
  const {id } = useParams();

  // console.log(id)

  useEffect(() => {
    // Fetch the transaction data for a single user from your backend
    setLoader(true)
    const fetchTransaction = async () => {
      const jwt = localStorage.getItem("jwt");
      try {
        // const response = await fetch(`/api/transaction/${id}`); // Replace with your API endpoint and transaction ID
        const response = await Axios.get(`/api/admin/transactions/invoice?id=${id}`,{
          headers: {
            jwt: `${jwt}`,
            "Content-Type": "application/json",
          },
        })
        console.log(response)
        if (response.status === 200) {
          // Assuming response.data.invoice is an array
          setTransaction(response.data.invoice[0]);
          setLoader(false)
        }
          console.log(transaction)
       
      } catch (error) {
        console.error("Error fetching transaction:", error);
        setLoader(false)
      }
    };

    fetchTransaction();
  }, [id]);

  useEffect(() => {
    console.log("Transaction updated:", transaction);
  }, [transaction]);

  if (loader) {
    return <div>Loading...</div>;
  }

  // return (
  //   <div
  //     style={{
  //       // marginTop: "rem",
  //       padding: "20px",
  //       maxWidth: "800px",
  //       margin: "2rem auto",
  //       border: "1px solid #ddd",
  //       borderRadius: "8px",
  //       boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  //     }}
  //   >
  //     <div style={{ display: "flex" }}>
  //       <h2 style={{ flex: "1" }}>Transaction Details</h2>
  //       <button
  //         className="btn"
  //         style={{ background: "blue", color: "white" }}
  //         onClick={() => history.push(`/transactions`)}
  //       >
  //         Back
  //       </button>
  //     </div>

  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Username:</strong>
  //       {/* {transaction.username} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>User ID:</strong>
  //       {/* {transaction.userId} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Order ID:</strong>
  //       {/* {transaction.orderId} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Order Date and Time:</strong>
  //       {/* {new Date(transaction.orderDateTime).toLocaleString()} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Razorpay ID:</strong>
  //       {/* {transaction.razorpayId} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Package Name:</strong>
  //       {/* {transaction.packageName} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Amount:</strong>
  //       {/* {transaction.amount} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Status:</strong>
  //       {/* {transaction.status} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Referral Code:</strong>
  //       {/* {transaction.referralCode || "N/A"} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>Discount Amount:</strong>
  //       {/* {transaction.discountAmount || "N/A"} */}
  //     </div>
  //     <div style={{ marginBottom: "10px" }}>
  //       <strong>IP Address:</strong>
  //       {/* {transaction.ip} */}
  //     </div>
  //   </div>
  // );
  return (
    <div className="invoice-container container my-3 rounded p-3">
      

        {/* Render your transaction details here */}
      {transaction ? (
        <div className=""style={{width:"50%",minWidth:"400px"}}>
        <div className="invoice-header">
            <h1>Invoice</h1>
        </div>
        <table className="table table-striped">
            <tbody>
                <tr>
                    <th className="fs-6 fw-bold text-black">Order ID</th>
                    <td>
                      {transaction?.packageSelected?._id}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Order Date and Time</th>
                    <td>
                      {/* {transaction.packageSelected._id} */}
                      {new Date(transaction?.packageSelected?.startTime).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })};{" "}
  {new Date(transaction?.packageSelected?.startTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">User Name</th>
                    <td>
                      {transaction?.name || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Mobile Number</th>
                    <td>
                      {transaction?.number || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Plan Name</th>
                    <td>
                      {transaction?.packageSelected?.name || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Amount Paid</th>
                    <td>
                      {transaction?.packageSelected?.rate || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Referral Code (If Used)</th>
                    <td>
                      {transaction?.referralCode || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Discount (If Applied)</th>
                    <td>
                      {transaction?.discount || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Plan</th>
                    <td>
                      {transaction?.packageSelected?.name || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Payment Gateway Name</th>
                    <td>
                      {transaction?.payment || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Transaction ID</th>
                    <td>
                      {transaction?.packageSelected?.razorpay_order_id || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Order Status</th>
                    <td>
                      {transaction?.packageSelected?.subscriptionStatus || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">IP Address</th>
                    <td>
                      {transaction?.ipaddress || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Location</th>
                    <td>
                      {transaction?.location || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Android Version</th>
                    <td>
                      {transaction?.andriodVersion || "N/A"}
                      </td>
                </tr>
                <tr>
                    <th className="fs-6 fw-bold text-black">Phone Model</th>
                    <td>
                      {transaction?.phoneModel || "N/A"}
                      </td>
                </tr>
            </tbody>
        </table>
        <div className="footer">
            <p>Thank you for your order!</p>
        </div>
        </div>
      ) : (
        <div>No transaction found.</div>
      )}
    </div>
);
};

export default ViewTransaction;
