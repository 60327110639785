import React, { useEffect, useState } from "react";

import Axios from "../axios";
import { Button } from "bootstrap";
import { useHistory } from "react-router-dom";

export default () => {
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState();

  const getOrders = async () => {
    setLoading(true);
    const jwt = localStorage.getItem("jwt");

    try {
      const response = await Axios.get("/api/admin/transactions", {
        headers: {
          jwt: `${jwt}`,
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      setOrderDetails(response?.data?.packages);
    } catch (err) {
      console.log("error in get user", err);
    }
    setLoading(false);
  };

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  useEffect(() => {
    getOrders();
  }, []);

  const rowsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = orderDetails?.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(orderDetails?.length / rowsPerPage);

  const history = useHistory();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  console.log(orderDetails)
  return (
    <>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Transactions</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <TransactionsTable /> */}

      <section>
        <h1 className="text-center mb-1">Orders</h1>
        <div className="">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Unpaid</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Paid</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
  <table className="table table-hover table-responsive-xl table-responsive-md">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Package</th>
              <th scope="col">start date</th>
              <th scope="col">trial Period End Time</th>
              <th scope="col">Status</th>
              {/* <th scope="col">Action</th> */}
            </tr>
          </thead>

          {loading ? (
            <tbody className="w-100">
              <tr>
                <td colSpan="6" className="text-center">
                  <div colSpan="6" className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentRows ? (
                currentRows.map((item, index) => (
                  <tr key={index}>
                    <th>{item?.packageSelected?._id}</th>
                    <th>{item?.name}</th>
                    <th>{item?.packageSelected?.name}</th>
                    <th>
                    {new Date(item?.packageSelected?.startTime).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })};{" "}
  {new Date(item?.packageSelected?.startTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}
                    </th>
                    <th>
                    {new Date(item?.packageSelected?.trialPeriodEndTime).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })};{" "}
  {new Date(item?.packageSelected?.trialPeriodEndTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}
                      {/* {new Date(
                        item?.packageSelected?.trialPeriodEndTime
                      ).toLocaleDateString("en-US", options)} */}
                    </th>
                    <th> {item?.packageSelected?.subscriptionStatus}</th>
                    {/* <th>
                    <button
                        className="btn"
                        style={{ background: "black", color: "white" }}
                        onClick={() =>
                          history.push(`/transactions/${item?._id}`)
                        }
                      >
                        View
                      </button>
                    </th> */}
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan="6" className="text-center">
                      <p>No Any Order</p>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          )}
        </table>
  

        <div style={{ cursor: "pointer" }}>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </div>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <div
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}{" "}
                  {currentPage === index + 1 && (
                    <span className="sr-only">(current)</span>
                  )}
                </div>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </div>
            </li>
          </ul>
        </div>

  </div>
  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
  <table className="table table-hover table-responsive-xl table-responsive-md">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Package</th>
              <th scope="col">start date</th>
              <th scope="col">trial Period End Time</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>

          {loading ? (
            <tbody className="w-100">
              <tr>
                <td colSpan="6" className="text-center">
                  <div colSpan="6" className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentRows ? (
                currentRows.map((item, index) => (
                  <tr key={index}>
                    <th>{item?.packageSelected?._id}</th>
                    <th>{item?.name}</th>
                    <th>{item?.packageSelected?.name}</th>
                    <th>
                    {new Date(item?.packageSelected?.startTime).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })};{" "}
  {new Date(item?.packageSelected?.startTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}
                    </th>
                    <th>
                    {new Date(item?.packageSelected?.trialPeriodEndTime).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })};{" "}
  {new Date(item?.packageSelected?.trialPeriodEndTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}
                      {/* {new Date(
                        item?.packageSelected?.trialPeriodEndTime
                      ).toLocaleDateString("en-US", options)} */}
                    </th>
                    <th> {item?.packageSelected?.subscriptionStatus}</th>
                    <th>
                      {" "}
                      {/* <Button>View</Button> */}
                      <button
                        className="btn"
                        style={{ background: "black", color: "white" }}
                        onClick={() =>
                          history.push(`/transactions/${item?._id}`)
                        }
                      >
                        View
                      </button>
                    </th>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan="6" className="text-center">
                      <p>No Any Order</p>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          )}
        </table>

        <div style={{ cursor: "pointer" }}>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </div>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <div
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}{" "}
                  {currentPage === index + 1 && (
                    <span className="sr-only">(current)</span>
                  )}
                </div>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </div>
            </li>
          </ul>
        </div>
  </div>
</div>
        </div>
      </section>
    </>
  );
};
