import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
// import Presentation from "./Presentation";

import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";

// import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";

import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
// import DocsOverview from "./documentation/DocsOverview";
// import DocsDownload from "./documentation/DocsDownload";
// import DocsQuickStart from "./documentation/DocsQuickStart";
// import DocsLicense from "./documentation/DocsLicense";
// import DocsFolderStructure from "./documentation/DocsFolderStructure";
// import DocsBuild from "./documentation/DocsBuild";
// import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

// import Breadcrumbs from "./components/Breadcrumbs";
// import Buttons from "./components/Buttons";
// import Forms from "./components/Forms";
// import Modals from "./components/Modals";
// import Navs from "./components/Navs";
// import Navbars from "./components/Navbars";
// import Pagination from "./components/Pagination";
// import Popovers from "./components/Popovers";
// import Progress from "./components/Progress";
// import Tables from "./components/Tables";
// import Tabs from "./components/Tabs";
// import Tooltips from "./components/Tooltips";
// import Toasts from "./components/Toasts";
// import Users from "../components/Users";
import Messages from "./examples/Messages";
import ChangePassword from "./examples/ChangePassword";
import MyProfile from "./examples/MyProfile";
import Users from "./Users/Users";
import ViewUser from "./Users/viewUser";
import { PageVisitsTable } from "../components/Tables";
import ViewTransactions from "./viewTransactions";
import SupportRequest from "./CustomerSupport/SupportRequest";
import ContactRequest from "./CustomerSupport/ContactRequest";
import SupportReply from "./CustomerSupport/SupportReply";
import SupportRequestClosed from "./CustomerSupport/SupportRequestClosed";
import CloseRequestView from "./CustomerSupport/CloseRequestView";
import Plan from "./Plan/plan";
import AddPlan from "./Plan/addPlan";
import UpdatePlan from "./Plan/updatePlan";
import DownloadUrl from "./Settings/downloadUrl";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    // return localStorage.getItem("settingsVisible") === "false" ? false : true;
    return true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    {/* <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} /> */}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />

    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    {/* <RouteWithLoader exact path={Routes.Lock.path} component={Lock} /> */}
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />
    <RouteWithLoader
      exact
      path={Routes.ChangePassword.path}
      component={ChangePassword}
    />
    <RouteWithLoader exact path={Routes.MyProfile.path} component={MyProfile} />

    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.Presentation.path}
      component={DashboardOverview}
    />
    <RouteWithSidebar exact path={Routes.Messages.path} component={Messages} />
    <RouteWithSidebar exact path={Routes.user.path} component={Users} />
    <RouteWithSidebar exact path="/users/:id" component={ViewUser} />
    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar
      exact
      path="/transactions/:id"
      component={ViewTransactions}
    />
    {/* Plan */}
    <RouteWithSidebar exact path="/plan" component={Plan} />
    <RouteWithSidebar exact path="/add-plan" component={AddPlan} />
    <RouteWithSidebar exact path="/update-plan/:id" component={UpdatePlan} />
    <RouteWithSidebar
      exact
      path="/customer/support"
      component={SupportRequest}
    />
    <RouteWithSidebar
      exact
      path="/customer/support/close"
      component={SupportRequestClosed}
    />
    <RouteWithSidebar
      exact
      path="/customer/support/view/:ticketID"
      component={CloseRequestView}
    />
    <RouteWithSidebar
      exact
      path="/customer/support/reply/:ticketID"
      component={SupportReply}
    />
    <RouteWithSidebar
      exact
      path="/contact/request"
      component={ContactRequest}
    />
    {/* Settings */}
    <RouteWithSidebar
      exact
      path="/settings/download-url"
      component={DownloadUrl}
    />
    {/* <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} /> */}
    {/* <RouteWithSidebar
      exact
      path={Routes.BootstrapTables.path}
      component={BootstrapTables}
    /> */}

    {/* components */}
    {/* <RouteWithSidebar
      exact
      path={Routes.Accordions.path}
      component={Accordion}
    /> */}

    {/* <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} /> */}
    {/* <RouteWithSidebar
      exact
      path={Routes.Breadcrumbs.path}
      component={Breadcrumbs}
    /> */}
    {/* <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar
      exact
      path={Routes.Pagination.path}
      component={Pagination}
    />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} /> */}

    {/* documentation */}
    {/* <RouteWithSidebar
      exact
      path={Routes.DocsOverview.path}
      component={DocsOverview}
    />
    <RouteWithSidebar
      exact
      path={Routes.DocsDownload.path}
      component={DocsDownload}
    />
    <RouteWithSidebar
      exact
      path={Routes.DocsQuickStart.path}
      component={DocsQuickStart}
    />
    <RouteWithSidebar
      exact
      path={Routes.DocsLicense.path}
      component={DocsLicense}
    />
    <RouteWithSidebar
      exact
      path={Routes.DocsFolderStructure.path}
      component={DocsFolderStructure}
    />
    <RouteWithSidebar
      exact
      path={Routes.DocsBuild.path}
      component={DocsBuild}
    />
    <RouteWithSidebar
      exact
      path={Routes.DocsChangelog.path}
      component={DocsChangelog}
    /> */}

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
