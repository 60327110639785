import axios from "axios";

const Axios = axios.create({
  //baseURL: "http://localhost:5000",
  withCredentials: true,
  // baseURL: "http://192.168.31.87:5000/",
  // baseURL: 'https://leadesh-whatsapp.onrender.com',
  baseURL: "https://api.leadesh.com", // latest is this one
  // baseURL: "https://leadesh-api-github.onrender.com/",
});

export default Axios;
