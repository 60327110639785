import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Card } from "@themesberg/react-bootstrap";
// import { useNavigate } from "react-router-dom";
import axios from "axios";

// import Fuse from "fuse.js";
import Axios from "../../axios";
import Swal from "sweetalert2";
const SupportRequestClosed = () => {
  const jwt = localStorage.getItem("jwt");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [status, setStatus] = useState("");

  const [SupportRequestsData, setSupportRequestsData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [showData, setShowData] = useState(SupportRequestsData);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (searchTerm !== "") {
        let searchedResult = [];
        searchedResult = SupportRequestsData.filter((item) =>
          item.ticketId.toString().includes(searchTerm)
        );

        setSupportRequestsData(searchedResult);
      } else {
        getSupportTicketsData();
      }
    }, 100);
  }, [searchTerm]);

  const handleShowEntries = (e) => {
    setCurrentPage(1);
    setItemPerPage(e.target.value);
  };
  const [ticketsData, setticketsData] = React.useState([]);
  const getSupportTicketsData = async () => {
    Axios.get(`/api/support/getAll/`, {
      headers: {
        jwt: `${jwt}`,
      },
    })
      .then((res) => {
        setSupportRequestsData(
          res.data?.support.filter((ticket) => ticket.status === "Close")
        );
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "please login to access the resource or refresh the page  ",
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
        setLoading(false);
      });
  };
  React.useEffect(() => {
    setLoading(true);
    getSupportTicketsData();
  }, [jwt]);

  //  console.log(showData);

  const rowsPerPage = 8;
  // const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = SupportRequestsData?.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  console.log(SupportRequestsData);

  const totalPages = Math.ceil(SupportRequestsData?.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  //********************************* */
  useEffect(() => {
    const loadData = () => {
      const indexOfLastPost = currentPage * itemPerPage;
      const indexOfFirstPost = indexOfLastPost - itemPerPage;
      setShowData(SupportRequestsData.slice(indexOfFirstPost, indexOfLastPost));
    };
    loadData();
  }, [currentPage, itemPerPage, SupportRequestsData]);

  return (
    <section>
      <h1 className="text-center mb-1">Customer Support Requests </h1>
      <Card>
        {/* <div class="m-2">
        <div class="">
          <div class="col-md-1">
            <label for="showEntries">Show Entries:</label>
            <select
              onChange={(e) => handleShowEntries(e)}
              class="form-control"
              id="showEntries"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div> */}
        <div style={{ margin: "1rem", display: "flex" }}>
          <button
            style={{
              background: "blue",
              color: "white",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
            }}
            onClick={() => history.push("/customer/support")}
          >
            open request
          </button>
          <button
            style={{
              background: "black",
              color: "white",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
              boxShadow: "none",
              marginRight: "3rem",
            }}
          >
            close request
          </button>
          <div style={{ display: "flex" }}>
            <label for="usr">Search by ticket id :</label>
            <input
              type="text"
              class="form-control"
              id="usr"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by ticket id "
            />
          </div>
        </div>
        <table className="table table-hover table-responsive-xl table-responsive-md">
          <thead>
            <tr>
              <th className="text-start">Ticket ID</th>
              <th className="text-start">Subject</th>
              <th className="text-start">Created On</th>
              <th className="text-start">Last Reply</th>
              <th className="text-start">Status</th>
              <th className="text-start" style={{ paddingLeft: "15px" }}>
                Action
              </th>
            </tr>
          </thead>
          {loading ? (
            <tbody className="w-100">
              <tr>
                <td colSpan="7" className="text-center">
                  <div colSpan="7" className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentRows.length > 0 ? (
                currentRows.map((ticket, index) => (
                  <tr key={index}>
                    <td className="text-start">{ticket.ticketId}</td>
                    <td className="text-start">{ticket.subject}</td>
                    <td className="text-start">{ticket.createdOn}</td>
                    <td className="text-start">
                      {ticket.lastreply ? ticket.lastreply : "Not replied yet "}
                    </td>
                    <td className="text-start">{ticket.status}</td>
                    <td>
                      <button
                        style={{
                          background: "blue",
                          color: "white",
                          padding: "0.5rem 1rem",
                          borderRadius: "1rem",
                        }}
                        onClick={() => {
                          history.push(
                            `/customer/support/view/${ticket?.ticketId}`,
                            {
                              replace: true,
                            }
                          );
                        }}
                      >
                        View
                      </button>
                    </td>
                    {/* <th>
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn"
                        style={{ background: "black", color: "white" }}
                        onClick={() => history.push(`/users/${item?.id}`)}
                      >
                        View
                      </button>

                      <ToggleButtonGroup
                        type="radio"
                        name={`statusToggle${item.id}`}
                        value={item.isActive}
                      >
                        <ToggleButton
                          value={true}
                          variant="success"
                          disabled={item.isActive}
                          onClick={() => toggleStatus(item.id, item.isActive)}
                        >
                          Active
                        </ToggleButton>
                        <ToggleButton
                          value={false}
                          variant="warning"
                          disabled={!item.isActive}
                          onClick={() => toggleStatus(item.id, item.isActive)}
                        >
                          Suspended
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteUser(item.id, item?.number)}
                      >
                        Delete
                      </button>
                    </div>
                  </th> */}
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan="7" className="text-center">
                      <p>No ticket available</p>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          )}
        </table>
        <div style={{ cursor: "pointer", marginTop: "1rem" }}>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </div>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <div
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}{" "}
                  {currentPage === index + 1 && (
                    <span className="sr-only">(current)</span>
                  )}
                </div>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </div>
            </li>
          </ul>
        </div>
      </Card>
    </section>
  );
};

export default SupportRequestClosed;
