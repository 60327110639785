import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// import swal from "sweetalert";
import axios from "axios";
// import MessageList from "./MessageList";
// import toast from "react-hot-toast";
import { Button } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import Axios from "../../axios";
import MessageList from "./MessageList";
// import Button from "react-bootstrap/Button";

const SupportReply = () => {
  const history = useHistory();
  const [message, setMessage] = useState("");
  console.log(message);
  const [data, setData] = useState({
    user: "admin",
    replyDate: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  // const handleChange = (e) => {
  //   setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  // };

  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const { ticketID } = useParams();
  console.log(ticketID);
  const [ticketDetails, setticketDetails] = useState(null);
  const [fetchData, setFetchData] = useState(false);
  const jwt = localStorage.getItem("jwt");

  const getSupportTicketDetails = async () => {
    Axios.get(`/api/support/getOne/${ticketID}`, {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        jwt: `${jwt}`,
      },
    })
      .then((res) => {
        console.log(res);
        setticketDetails(res.data?.support);
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "Please login to access the resource or refresh the page",
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };

  const handleSubmit = () => {
    if (message.trim() === "") {
      Swal.fire({
        title: "Warning",
        text: "Fill all mandatory fields",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }
    setLoading(true);
    const formData = {
      message: message,
      replyDate: new Date().toLocaleString("en-US", options),
      user: "admin",
    };
    Axios.patch(
      `/api/support/update/${ticketID}`,
      {
        message: formData,
      },
      {
        headers: {
          jwt: `${jwt}`,
        },
      }
    )
      .then((res) => {
        Swal.fire({
          title: "Messsge sent successfully ",
          // text: "Fill all mandatory fields",
          icon: "success",
          button: "Close",
          dangerMode: true,
        });
        setLoading(false);
        setMessage("");
        setFetchData(true);
        history.replace("/customer/support");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          button: "Close",
        });
        setLoading(false);
      });
  };

  // const getuserName = async (ticket) => {
  //   try {
  //     const userId = ticket.addedBy;
  //     let resp = await axios.get(`/api/v1/admin/user/${userId}`, {
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //       },
  //     });
  //     const userName = resp?.data?.user?.name;
  //     const userMailId = resp?.data?.user?.email;
  //     const ticketWithUserName = {
  //       ...ticket,
  //       userName: userName,
  //       userMailId: userMailId,
  //     };
  //     setticketDetails(ticketWithUserName);
  //   } catch (error) {
  //     // Handle errors
  //   }
  // };

  useEffect(() => {
    getSupportTicketDetails();
    setFetchData(false);
  }, [fetchData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div style={{ fontSize: "22px" }} className="fw-bold">
              Support Chat
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 my-1">
          <div className="card h-100">
            <div className="card-body px-5">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Ticket ID: {ticketDetails?.ticketId}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  User ID: {ticketDetails?.addedBy?._id}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  User Name: {ticketDetails?.addedBy?.name}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  User Number: {ticketDetails?.addedBy?.number}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  Date and Time: {ticketDetails?.createdOn}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  Subject: {ticketDetails?.subject}
                </label>
                <br />
                <label htmlFor="name" className="form-label">
                  Description:{" "}
                  {ticketDetails?.description
                    ? ticketDetails?.description
                    : "No Description"}
                </label>
                <br />
              </div>
              <div className="mb-3">
                {ticketDetails?.image?.length > 0 && (
                  <div>
                    <label htmlFor="name" className="form-label">
                      Image:
                    </label>
                    <div className="image-list d-flex flex-wrap">
                      {ticketDetails?.image?.map((item) => (
                        <div key={item.public_id} className="image-list-item">
                          <img
                            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.title || "No image"}
                            loading="lazy"
                            className="img-thumbnail"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-3">
                {ticketDetails && (
                  <MessageList messages={ticketDetails?.message} />
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Message *
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="10"
                  cols="100"
                  value={message}
                  placeholder="your message..."
                  maxLength="500"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                {data.message && (
                  <small className="charLeft mt-4 fst-italic">
                    {500 - data.message.length} characters left
                  </small>
                )}
              </div>
              <div className="mb-3">
                <Button
                  variant="primary"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    textTransform: "capitalize",
                    marginRight: "5px",
                  }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Loading" : "Submit"}
                </Button>
                <Button
                  variant="outline-danger"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    Swal.fire({
                      title: "Warning",
                      text: "Are you sure you want to go back?",
                      icon: "error",
                      buttons: ["No", "Yes"],
                      dangerMode: true,
                    }).then((value) => {
                      if (value) {
                        history.replace("/customer/support");
                      }
                    });
                  }}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportReply;
