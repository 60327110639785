import React, { useState, useEffect } from "react";
import Axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCashRegister,
  faChartLine,
  faCloudUploadAlt,
  faPlus,
  faRocket,
  faTasks,
  faUserShield,
  faComments,
  faCommentsDollar,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Container,
  Button,
  Dropdown,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import {
  CounterWidget,
  CircleChartWidget,
  BarChartWidget,
  TeamMembersWidget,
  ProgressTrackWidget,
  RankingWidget,
  SalesValueWidget,
  SalesValueWidgetPhone,
  AcquisitionWidget,
} from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import axios from "axios";

export default () => {
  const [dashboardData, setDashboardData] = useState();
  const [loading, setLoading] = useState(false);
  const jwt = localStorage.getItem("jwt");
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [suspendedUserCount, setSuspendedUserCount] = useState(0);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [weeklyStats, setWeeklyStats] = useState([]);
  const [yearlyStats, setYearlyStats] = useState([]);
  const [statsLoading, setStatsLoading] = useState(true);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("/api/admin/users", {
        headers: {
          jwt: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      setActiveUserCount(response?.data?.activeUserCount);
      setSuspendedUserCount(response?.data?.suspendedUserCount);
    } catch (err) {
      console.log("error in get user", err);
    }
    setLoading(false);
  };

  const getStats = async () => {
    try {
      const response = await Axios.get("/api/admin/getStats");
      setStatsLoading(false);

      console.log(response.data);

      const yearStats = response.data.yearlyStats;

      const modifiedData = yearStats.map((entry) => ({
        ...entry,
        month: entry.month.slice(0, 3),
      }));

      setMonthlyStats(response.data.monthlyStats);
      setYearlyStats(modifiedData);
      setWeeklyStats(response.data.weeklyStats);
    } catch (e) {
      console.log(e.response.data);
      setStatsLoading(false);
    }
  };

  useEffect(() => {
    // getDashboardData();
    getUsers();
    getStats();
  }, []);

  console.log(monthlyStats, weeklyStats, yearlyStats);

  return (
    <>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            size="sm"
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New Task
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />{" "}
              Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview
              Security
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" />{" "}
              Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <ButtonGroup>
          <Button variant="outline-primary" size="sm">
            Share
          </Button>
          <Button variant="outline-primary" size="sm">
            Export
          </Button>
        </ButtonGroup>
      </div> */}

      <Row className="justify-content-md-center">
        {/* <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value={dashboardData?.totalSalesAmount ?? "---"}
            percentage={10.57}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col> */}
        {loading ? (
          <div colSpan="6" className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Total Users"
                title={activeUserCount + suspendedUserCount || ""}
                icon={faChartLine}
                iconColor="shape-secondary"
                // bgColor="rgb(147 197 253)"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Active Users"
                title={activeUserCount}
                icon={faUserShield}
                iconColor="shape-secondary"
                // bgColor="rgb(134 239 172)"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Suspended Users"
                title={suspendedUserCount}
                icon={faEye}
                iconColor="shape-secondary"
                //  bgColor="rgb(254 202 202)"
              />
            </Col>

            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Paid Users"
                title={2}
                period="Feb 1 - Apr 1"
                percentage={28.4}
                icon={faCommentsDollar}
                iconColor="shape-tertiary"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Total Keywords"
                title={4}
                period="Feb 1 - Apr 1"
                percentage={28.4}
                icon={faComments}
                iconColor="shape-tertiary"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4"></Col>

            {/* <Col xs={12} sm={6} xl={4} className="mb-4">
              <CircleChartWidget title="Traffic Share" data={trafficShares} />
            </Col> */}
          </>
        )}
      </Row>

      {/* <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders}
                  />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> */}

      {statsLoading ? (
        <h2> Stats Loading</h2>
      ) : (
        <Container fluid className="mt-4">
          <Row className="mb-4">
            <Col xs={12}>
              <Card>
                <Card.Header as="h5">Weekly Activity</Card.Header>
                <Card.Body>
                  <div style={{ width: "100%", height: "300px" }}>
                    <ResponsiveContainer>
                      <BarChart
                        data={weeklyStats}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12}>
              <Card>
                <Card.Header as="h5">Monthly Activity</Card.Header>
                <Card.Body>
                  <div style={{ width: "100%", height: "300px" }}>
                    <ResponsiveContainer>
                      <LineChart
                        data={monthlyStats}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="count"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={12}>
              <Card>
                <Card.Header as="h5">Yearly Activity</Card.Header>
                <Card.Body>
                  <div style={{ width: "100%", height: "300px" }}>
                    <ResponsiveContainer>
                      <BarChart
                        data={yearlyStats}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
