import React, { useEffect, useState } from "react";
import Axios from "../../axios";
import {
  Card,
  ToggleButton,
  ToggleButtonGroup,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";

const ContactRequest = () => {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ContactRequestsData, setContactRequestsData] = useState([]);
  const [success, setSuccess] = useState(true);
  // const [itemPerPage, setItemPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const jwt = localStorage.getItem("jwt");
  const history = useHistory();

  const getContactRequestsData = async () => {
    Axios.get(`/api/contact/request/getAll/`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        setContactRequestsData(res.data?.contactRequest);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getContactRequestsData();
  }, [success]);
  console.log(ContactRequestsData);

  // const handleShowEntries = (e) => {
  //   setCurrentPage(1);
  //   setItemPerPage(e.target.value);
  // };
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const rowsPerPage = 8;
  // const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = ContactRequestsData?.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const totalPages = Math.ceil(ContactRequestsData?.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <section>
      <h1 className="text-center mb-1">Customer Requests </h1>
      <Card>
        {/* <div class="m-2">
          <div class="">
            <div class="col-md-1">
              <label for="showEntries">Show Entries:</label>
              <select
                onChange={(e) => handleShowEntries(e)}
                class="form-control"
                id="showEntries"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div> */}

        <table className="table table-hover table-responsive-xl table-responsive-md">
          <thead>
            <tr>
              <th className="text-start">Name</th>
              <th className="text-start">Email/Phone</th>
              <th className="text-start">Messgae</th>
              <th className="text-start">Date and time</th>
              {/* <th className="text-start">Status</th>
              <th className="text-start" style={{ paddingLeft: "15px" }}>
                Action
              </th> */}
            </tr>
          </thead>
          {loading ? (
            <tbody className="w-100">
              <tr>
                <td colSpan="7" className="text-center">
                  <div colSpan="7" className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentRows ? (
                currentRows.map((item, index) => (
                  <tr key={index}>
                    <th>{item.name}</th>

                    <th>{item?.EmailOrMobile}</th>
                    <th>{item?.message}</th>
                    <td className="text-start">
                      {new Date(item?.createdAt).toLocaleString("en-IN", {
                        weekday: "short",
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan="7" className="text-center">
                      <p>No ticket available</p>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          )}
        </table>
        <div style={{ cursor: "pointer", marginTop: "1rem" }}>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </div>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <div
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}{" "}
                  {currentPage === index + 1 && (
                    <span className="sr-only">(current)</span>
                  )}
                </div>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </div>
            </li>
          </ul>
        </div>
      </Card>
    </section>
  );
};

export default ContactRequest;
