import React from "react";

const MessageList = ({ messages }) => {
  return (
    <>
      {messages.map((msg, index) => (
        <div
          key={index}
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: msg.user === "user" ? "white" : "lightgray",
            boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
            {msg.user === "user" ? "User" : "Admin"}
          </div>
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
            {msg.replyDate}
          </div>
          <div>{msg.message}</div>
        </div>
      ))}
    </>
  );
};

export default MessageList;
