import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "@themesberg/react-bootstrap";

import Swal from "sweetalert2";
import Axios from "../../axios";
import { useHistory, useParams } from "react-router-dom";

const UpdatePlan = () => {
  const { id } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    planName: "",
    monthlyPrice: "",
    yearlyPrice: "",
    maxkeywords: "",

    text1: "",
    text2: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const jwt = localStorage.getItem("jwt");

  const getSinglePlan = async () => {
    try {
      const response = await Axios.get(`/api/get-plan/${id}`);

      const plan = response?.data.singlePlan;

      // Adjust the structure and types as needed
      const formattedData = {
        planName: plan.planName || "",
        monthlyPrice: plan.monthlyPrice || "",
        yearlyPrice: plan.yearlyPrice || "",
        maxkeywords: plan.maxkeywords || "",

        text1: plan.text1 || "",
        text2: plan.text2 || "",
      };

      setFormData(formattedData);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error on finding the plan to update.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.put(
        `/api/update-plan/${id}`,
        {
          ...formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        Swal.fire({
          title: "Success!",
          text: "Plan Updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        history.push("/plan");
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error on updating  the plan.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    getSinglePlan();
  }, []);
  return (
    <Container>
      <Card style={{ padding: "1rem", margin: "1rem" }}>
        <Row className="justify-content-md-center">
          <Col md="6">
            <h1 className="text-center mb-4">Update Plan</h1>
            <Form onSubmit={(e) => handleUpdate(e)}>
              <Form.Group controlId="formPlanName" className="mt-3">
                <Form.Label>Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  name="planName"
                  value={formData.planName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Monthly Price</Form.Label>
                <Form.Control
                  type="number"
                  name="monthlyPrice"
                  value={formData.monthlyPrice}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Yearly Price </Form.Label>
                <Form.Control
                  type="number"
                  name="yearlyPrice"
                  value={formData.yearlyPrice}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formKeywords" className="mt-3">
                <Form.Label>Keywords</Form.Label>
                <Form.Control
                  type="number"
                  name="maxkeywords"
                  value={formData.maxkeywords}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              {/* <Form.Group controlId="formTrialPeriod" className="mt-3">
                <Form.Label>Trial Period (days)</Form.Label>
                <Form.Control
                  type="number"
                  name="trialPeriod"
                  value={formData.trialPeriod}
                  onChange={handleChange}
                  required
                />
              </Form.Group> */}
              <Form.Group controlId="formTest" className="mt-3">
                <Form.Label>Text1</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text1"
                  name="text1"
                  value={formData.text1}
                  onChange={handleChange}
                  maxLength="100"
                  // required
                />
                {formData.text1.length > 0 && (
                  <Form.Text className="text-muted">
                    {100 - formData.text1.length} characters left
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="formText" className="mt-3">
                <Form.Label>Text2</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text2"
                  name="text2"
                  value={formData.text2}
                  onChange={handleChange}
                  maxLength="100"
                  // required
                />
                {formData.text2.length > 0 && (
                  <Form.Text className="text-muted">
                    {100 - formData.text2.length} characters left
                  </Form.Text>
                )}
              </Form.Group>

              <Button variant="info" type="submit" className="mt-3">
                Update
              </Button>
              <Button
                variant="danger"
                style={{ marginLeft: "1rem" }}
                className="mt-3 ml-3"
                onClick={() => {
                  history.push("/plan");
                }}
              >
                Back
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default UpdatePlan;
