import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Axios from "../../axios";
import { ToggleButton, ToggleButtonGroup } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Col, Row } from "@themesberg/react-bootstrap";
import { CounterWidget } from "../../components/Widgets";

import { faChartLine, faUserShield } from "@fortawesome/free-solid-svg-icons";

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const jwt = localStorage.getItem("jwt");
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [suspendedUserCount, setSuspendedUserCount] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(false); // Track updates
  const history = useHistory();

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("/api/admin/users", {
        headers: {
          jwt: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      setActiveUserCount(response?.data?.activeUserCount);
      setSuspendedUserCount(response?.data?.suspendedUserCount);
      setUserData(response?.data?.users);
    } catch (err) {
      console.log("error in get user", err);
    }
    setLoading(false);
  };

  const toggleStatus = async (userId, isActive) => {
    try {
      const response = await Axios.put(
        `/api/admin/users/update/${userId}`,
        {
          isActive: !isActive,
        },
        {
          headers: {
            jwt: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);

      setUserData((prevUserData) =>
        prevUserData.map((user) => {
          if (user.id === userId) {
            return {
              ...user,
              isActive: !isActive,
            };
          }
          return user;
        })
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Status Updated Successfully",
          text: "User status has been updated successfully!",
          // button: "OK",
          confirmButtonText: "OK",
        });
        setUpdateToggle((prev) => !prev);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "something went wronge",

        button: "OK",
      });
      console.error("Error updating isActive:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [updateToggle]);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata",
  };

  const rowsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = userData?.slice(indexOfFirstRow, indexOfLastRow);

  // console.log(userData);

  const totalPages = Math.ceil(userData?.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const deleteUser = async (userId, phoneNumber) => {
    // console.log("data;",userId,phoneNumber);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Axios.delete(
            `/api/admin/users/delete/${userId}`,
            {
              headers: {
                jwt: `${jwt}`,
                "Content-Type": "application/json",
              },
              data: {
                phoneNumber: phoneNumber, // Include phoneNumber in the request body
              },
            }
          );
          // console.log(response);
          if (response.status === 200) {
            // Remove the deleted user from the UI
            setUserData((prevUserData) =>
              prevUserData.filter((user) => user.id !== userId)
            );
            Swal.fire({
              icon: "success",
              title: "User Deleted Successfully",
              text: "User has been deleted successfully!",
              button: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting user:", error);
          Swal.fire({
            icon: "error",
            title: "Failed to delete user",
            text: "An error occurred while deleting the user.",
            button: "OK",
          });
        }
      } else {
        // If the user cancels the delete action, log or do nothing
        console.log("Deletion canceled");
      }
    });
  };

  return (
    <section>
      <Row className="justify-content-md-center mt-2">
        {loading ? (
          <div colSpan="6" className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Total Users"
                title={activeUserCount + suspendedUserCount || ""}
                icon={faChartLine}
                iconColor="shape-secondary"
                bgColor="rgb(147 197 253)"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Active Users"
                title={activeUserCount}
                icon={faUserShield}
                iconColor="shape-secondary"
                bgColor="rgb(134 239 172)"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Suspended Users"
                title={suspendedUserCount}
                icon={faEye}
                iconColor="shape-secondary"
                bgColor="rgb(254 202 202)"
              />
            </Col>
          </>
        )}
      </Row>
      {/* <div className="d-flex align-items-center justify-content-between">
      <h1 className="">Users : {activeUserCount+suspendedUserCount}</h1>
      <h2>Active : {activeUserCount}</h2>
      <h2>Suspended : {suspendedUserCount} </h2>
      </div> */}

      <table className="table table-hover table-responsive-xl table-responsive-md">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Joined On</th>
            <th scope="col">Package</th>
            <th scope="col">Keywords</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        {loading ? (
          <tbody className="w-100">
            <tr>
              <td colSpan="7" className="text-center">
                <div colSpan="7" className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {currentRows ? (
              currentRows.map((item, index) => (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <th>{item.name}</th>
                  <th>
                    {new Date(item.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </th>
                  <th>{item?.subscription.plan.planName}</th>
                  <th>{item?.keywords.length}</th>
                  <th>{item?.isActive === true ? "Active" : "Suspended"}</th>
                  <th>
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn"
                        style={{ background: "black", color: "white" }}
                        onClick={() => history.push(`/users/${item?.id}`)}
                      >
                        View
                      </button>
                      <button
                        className="btn"
                        style={{ background: "black", color: "white" }}
                      >
                        Invoices
                      </button>

                      {/* <ToggleButtonGroup
                        type="radio"
                        name={`statusToggle${item.id}`}
                        value={item.isActive}
                      >
                        <ToggleButton
                          value={true}
                          variant="success"
                          disabled={item.isActive}
                          onClick={() => toggleStatus(item.id, item.isActive)}
                        >
                          Active
                        </ToggleButton>
                        <ToggleButton
                          value={false}
                          variant="warning"
                          disabled={!item.isActive}
                          onClick={() => toggleStatus(item.id, item.isActive)}
                        >
                          Suspended
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteUser(item.id, item?.number)}
                      >
                        Delete
                      </button> */}
                    </div>
                  </th>
                </tr>
              ))
            ) : (
              <>
                <tr>
                  <td colSpan="7" className="text-center">
                    <p>User Not Found</p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        )}
      </table>

      <div style={{ cursor: "pointer" }}>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <div
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </div>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}{" "}
                {currentPage === index + 1 && (
                  <span className="sr-only">(current)</span>
                )}
              </div>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <div
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Users;
