import React, { useEffect, useState } from "react";
import Axios from "../../axios";
import { Card } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const SupportRequest = () => {
  const [SupportRequestsData, setSupportRequestsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showData, setShowData] = useState(SupportRequestsData);
  const jwt = localStorage.getItem("jwt");
  const history = useHistory();

  const getSupportTicketsData = async () => {
    Axios.get(`/api/support/getAll/`, {
      headers: {
        jwt: `${jwt}`,
      },
    })
      .then((res) => {
        console.log(res);
        setSupportRequestsData(
          res.data?.support.filter((ticket) => ticket.status === "Open")
        );
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "please login to access the resource or refresh the page  ",
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
        setLoading(false);
      });
  };

  const statusupdate = (ticketId) => {
    Axios.patch(
      `/api/support/update/${ticketId}`,
      { status: "Close" },
      { headers: { jwt: `${jwt}` } }
    )
      .then((res) => {
        Swal.fire({
          title: "Success",
          text: "Ticket Closed Successfully",
          icon: "success",
          button: "Close",
        });
        getSupportTicketsData();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          button: "Close",
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getSupportTicketsData();
  }, [jwt]);

  const rowsPerPage = 8;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = SupportRequestsData.slice(
    indexOfFirstRow,
    indexOfLastRow
  );
  const totalPages = Math.ceil(SupportRequestsData.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setTimeout(() => {
      if (searchTerm !== "") {
        let searchedResult = [];
        searchedResult = SupportRequestsData.filter((item) =>
          item.ticketId.toString().includes(searchTerm)
        );

        setSupportRequestsData(searchedResult);
      } else {
        getSupportTicketsData();
      }
    }, 100);
  }, [searchTerm]);

  return (
    <section>
      <h1 className="text-center mb-1">Customer Support Requests</h1>
      <Card>
        <div style={{ margin: "1rem", display: "flex" }}>
          <button
            style={{
              background: "blue",
              color: "white",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
            }}
          >
            Open Request
          </button>
          <button
            style={{
              background: "black",
              color: "white",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
              boxShadow: "none",
              marginRight: "3rem",
            }}
            onClick={() => history.push("/customer/support/close")}
          >
            Close Request
          </button>
          <div style={{ display: "flex" }}>
            <label htmlFor="usr">Search by ticket id:</label>
            <input
              type="text"
              className="form-control"
              id="usr"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by ticket id"
            />
          </div>
        </div>
        <table className="table table-hover table-responsive-xl table-responsive-md">
          <thead>
            <tr>
              <th className="text-start">Ticket ID</th>
              <th className="text-start">Subject</th>
              <th className="text-start">Created On</th>
              <th className="text-start">Last Reply</th>
              <th className="text-start">Status</th>
              <th className="text-start" style={{ paddingLeft: "15px" }}>
                Action
              </th>
            </tr>
          </thead>
          {loading ? (
            <tbody className="w-100">
              <tr>
                <td colSpan="7" className="text-center">
                  <div colSpan="7" className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentRows.length > 0 ? (
                currentRows &&
                currentRows.map((ticket, index) => (
                  <tr key={index}>
                    <td className="text-start">{ticket.ticketId}</td>
                    <td className="text-start">{ticket.subject}</td>
                    <td className="text-start">{ticket.createdOn}</td>
                    <td className="text-start">
                      {ticket.lastreply ? ticket.lastreply : "Not replied yet"}
                    </td>
                    <td className="text-start">{ticket.status}</td>
                    <td>
                      <button
                        style={{
                          background: "blue",
                          color: "white",
                          padding: "0.5rem 1rem",
                          borderRadius: "1rem",
                        }}
                        onClick={() => {
                          history.push(
                            `/customer/support/reply/${ticket.ticketId}`,
                            { replace: true }
                          );
                        }}
                      >
                        Reply
                      </button>
                      <button
                        style={{
                          background: "black",
                          color: "white",
                          padding: "0.5rem 1rem",
                          borderRadius: "1rem",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          Swal.fire({
                            title: "Warning",
                            text: "Are you sure you want to close this ticket?",
                            icon: "warning",
                            buttons: ["No", "Yes"],
                            dangerMode: true,
                          }).then((value) => {
                            if (value.isConfirmed) {
                              statusupdate(ticket.ticketId);
                            }
                          });
                        }}
                      >
                        Close
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    <p>No tickets available</p>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
        <div style={{ cursor: "pointer", marginTop: "1rem" }}>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </div>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <div
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}{" "}
                  {currentPage === index + 1 && (
                    <span className="sr-only">(current)</span>
                  )}
                </div>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <div
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </div>
            </li>
          </ul>
        </div>
      </Card>
    </section>
  );
};

export default SupportRequest;
